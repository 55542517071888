import React from 'react';
import './App.css';

const App = () => {
	return (
		<div>
			Testing
		</div>
	)
}

export default App;
